<template>
  <div>
    <v-form>
      <p class="mb-3">
        For which of the salts below, would the dissolution in water be an
        <b>endothermic</b> process?
      </p>

      <p v-for="option in optionsShuffled" :key="'pt-1-' + option.value" class="my-0 pl-6">
        <v-checkbox v-model="inputs.studentAnswers" :value="option.value" class="pl-0 mb-n4 mt-0">
          <template v-slot:label>
            <stemble-latex :content="option.text" />
          </template>
        </v-checkbox>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemMcMPL4_Q7b',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        studentAnswers: [],
      },
      options: [
        {text: '$\\ce{KBr}$', value: 'KBr'},
        {text: '$\\ce{LiCl}$', value: 'LiCl'},
        {text: '$\\ce{NH4Br}$', value: 'NH4Br'},
        {text: '$\\ce{KNO3}$', value: 'KNO3'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$inertia.getSeed();
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
